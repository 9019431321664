<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  provide: {
    '$imageLocal': (str, type) => {//图片链接
      return `https://oss.kbb1688.com/webItem/saasqdspc/${str}.${type ? type : 'png'}`;
    }
  }
}
</script>
<style lang="less">
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  overflow: hidden;
  font-family: '微软雅黑';
}
.app{
  height: 100%;
  color: #2c3e50;
}
.router-link-active {
  text-decoration: none;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar { 
   width: 6px; /*滚动条宽度*/
   height: 8px; /*滚动条高度*/
   background-color: #ebeef5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb { 
   -webkit-box-shadow: inset 0 0 0px white;
   background-color: rgb(193, 193, 193); /*滚动条的背景颜色*/
   border-radius: 30px;
}
</style>
