import { request } from '@/api/request';

// //权限池列表
// export const permissionList = data => {
// 	return request('get','/api/permission/permissionList',data);
// }

//------------------------------------------------------------------------

//角色列表
export const getRoleList = data => {
	return request('get','/api/permission/getRoleList',data);
}

//获取角色权限
export const getRolePermissionList = data => {
	return request('get','/api/permission/getRolePermissionList',data);
}

// 角色编辑/添加角色
export const addOrEditRole = data => {
	return request('post','/api/permission/addOrEditRole',data);
}

//删除角色
export const delRole = data => {
	return request('get','/api/permission/delRole',data);
}

//权限池列表
export const permissionList = data => {
	return request('get','/api/permission/permissionList',data);
}

//权限池添加
export const addPermission = data => {
	return request('post','/api/permission/addPermission',data);
}

//权限池编辑
export const editPermission = data => {
	return request('post','/api/permission/editPermission',data);
}

//获取商户账号列表
export const MerchantAdminlist = data => {
	return request('get','/api/Account/MerchantAdmin/list',data);
}

//商户账号创建
export const MerchantAdmincreate = data => {
	return request('post','/api/Account/MerchantAdmin/create',data);
}

//删除账号信息
export const remPlaformAccount = data => {
	return request('get','/api/accountPlatform/remPlaformAccount',data);
}

//商户账号修改
export const MerchantAdminmodify = data => {
	return request('post','/api/Account/MerchantAdmin/modify',data);
}

//商户账号启用/禁用
export const changeStatus = data => {
	return request('post','/api/Account/MerchantAdmin/changeStatus',data);
}

//商户账号删除
export const MerchantAdmindelete = data => {
	return request('post','/api/Account/MerchantAdmin/delete',data);
}

//商户账号密码修改
export const saveShopPassword = data => {
	return request('post','/api/Account/MerchantAdmin/saveMerchantPassword',data);
}
